import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

import params from "../../util/api_routes/params";
import api from "../../util/api";
import changeStage from "../../util/changeStage";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  submitDeclaration = async (e) => {
    e.preventDefault();
    console.log(`checked: ${this.state.declaration}`);

    // if (this.state.declaration) {
    // msg = "Checked";
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    // let result = await changeStage(payLoad);
    // if (result === 1) {
    await api
      .post(params.SUBMIT_APPLICATION, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              submit_application: res.data.data,
              loading: false,
              network_error: false,
            });

            console.log(this.state.submit_application.isQualified);
            // window.location.reload();
            this.props.history.push({
              state: {
                submit_application_data: this.state.submit_application,
              },
              pathname: "/after/submission",
            });
          } else {
            this.setState({
              network_error: true,
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, loading: false });
      });
    // }
    // }
  };

  render() {
    // console.log(typeof localStorage.getItem("sideBarStatus"));
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title"></li> */}
            {parseInt(localStorage.getItem("sideBarStatus")) === 1 ? (
              <>
                <li>
                  <Link to="/registration-feepayment" className="waves-effect">
                    <i
                      className="fas fa-money-bill-wave"
                      aria-hidden="true"
                    ></i>
                    <span className="ml-1">{this.props.t("Fee Payment")}</span>
                    {parseInt(localStorage.getItem("stageid")) > 10 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                  <Link to="/registration-personal" className="waves-effect">
                    <i className="fas fa-user-friends" aria-hidden="true"></i>
                    <span className="ml-1">
                      {this.props.t("Personal Details")}
                    </span>
                    {parseInt(localStorage.getItem("stageid")) > 11 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                  <Link to="/registration-employment" className="waves-effect">
                    <i className="far fa-building"></i>
                    <span className="ml-1">
                      {this.props.t("Employment Details")}
                    </span>
                    {parseInt(localStorage.getItem("stageid")) > 12 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>{" "}
                  <Link to="/registration-bank" className="waves-effect">
                    <i class="fas fa-donate"></i>{" "}
                    <span className="ml-1">
                      {this.props.t("Bank Account Details ##")}
                    </span>
                    {/* {parseInt(localStorage.getItem("stageid")) > 12 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null} */}
                  </Link>{" "}
                  <Link to="/registration-education" className="waves-effect">
                    <i className="fa fa-book" aria-hidden="true"></i>
                    <span className="ml-1">
                      {this.props.t("Olevel Education")}
                    </span>
                    {parseInt(localStorage.getItem("stageid")) > 13 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                  <Link to="/registration-nhif" className="waves-effect">
                    <i className="fa fa-certificate" aria-hidden="true"></i>
                    <span className="ml-1">{this.props.t("NHIF")}</span>
                    {parseInt(localStorage.getItem("stageid")) > 14 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                  <Link to="/registration-declaration" className="waves-effect">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <span className="ml-1">{this.props.t("Declaration")}</span>
                    {parseInt(localStorage.getItem("stageid")) > 15 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                  <Link to="/registration-completed" className="waves-effect">
                    <i className="fa fa-trophy" aria-hidden="true"></i>
                    <span className="ml-1">{this.props.t("Completed")}</span>
                    {parseInt(localStorage.getItem("stageid")) >= 16 ? (
                      <span className="ok">
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </span>
                    ) : null}
                  </Link>
                </li>
              </>
            ) : (
              <>
                {parseInt(localStorage.getItem("sideBarStatus")) === 2 ? (
                  <>
                    <li>
                      <Link to="/landing" className="waves-effect">
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <span className="ml-1">{this.props.t("Home")}</span>
                      </Link>
                      <Link to="/profile" className="waves-effect">
                        <i
                          className="fas fa-user-friends"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("My Profile")}
                        </span>
                      </Link>
                      <Link to="/education-background" className="waves-effect">
                        <i className="far fa-building"></i>
                        <span className="ml-1">
                          {this.props.t("Education Background")}
                        </span>
                      </Link>{" "}
                      <Link to="/examination-results" className="waves-effect">
                        <i className="fa fa-book" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Exam Results")}
                        </span>
                      </Link>
                      <Link to="/fee-payment" className="waves-effect">
                        <i
                          className="fas fa-money-bill-wave"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("Fee Payments")}
                        </span>
                      </Link>
                      <Link to="/program-modules" className="waves-effect">
                        <i
                          className="	fa fa-address-book"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("Programme Module")}
                        </span>
                      </Link>
                      <Link to="/clearance" className="waves-effect">
                        <i
                          className="fa fa-user-friends"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">
                          {this.props.t("Clearance")}
                        </span>
                      </Link>
                      {/* <Link to="/hostel" className="waves-effect">
                        <i
                          className="fa fa-user-friends"
                          aria-hidden="true"
                        ></i>
                        <span className="ml-1">{this.props.t("Hostel")}</span>
                      </Link> */}
                      <Link to="/registration" className="waves-effect">
                        <i className="fa fa-registered" aria-hidden="true"></i>
                        <span className="ml-1">
                          {this.props.t("Registration")}
                        </span>
                      </Link>
                      <Link to="/nhif-student" className="waves-effect">
                        {/* <Link to="/registration-nhif" className="waves-effect"> */}
                        <i className="fa fa-certificate" aria-hidden="true"></i>
                        <span className="ml-1">{this.props.t("NHIF ")}</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
