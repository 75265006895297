import axios from "api/axios";

const REGISTRATION_STATUS = "/api/student_registration/is-registered";
const REGISTER = "/api/student_registration/create";
const STUDENT_CARRY_REGISTRATION_RESULTS_API = "/api/carry-registrations";
const CARRY_REG = "/api/carry-registrations";
const CARRY_MODULES_DETAILS = "/api/carry-registrations/modules";

//SEARCH BY CONTROL NUMBER
const checkRegistrationStatus = async (params) => {
  return await axios.get(`${REGISTRATION_STATUS}`, { params });
};

const studentRegistration = async (payload) => {
  return await axios.post(`${REGISTER}`, payload);
};

const getStudentCarryRegistraionResults = async (payload) => {
  return await axios.get(
    `${STUDENT_CARRY_REGISTRATION_RESULTS_API}/${payload}`
  );
};

const carryRegistration = async (payload) => {
  return await axios.post(`${CARRY_REG}`, payload);
};

const carryModulesDetails = async (payload) => {
  return await axios.get(`${CARRY_MODULES_DETAILS}/${payload}`);
};

export {
  checkRegistrationStatus,
  studentRegistration,
  getStudentCarryRegistraionResults,
  carryRegistration,
  carryModulesDetails,
};
