// import React from "react";
// import { useSelector } from "react-redux";
// import Grid from "@material-ui/core/Grid";

// import "helpers/Preloader/preloader.scss";

// const Preloader = () => {
//   const state = useSelector((state) => state.preloader);
//   return (
//     <>
//       {state?.loading && (
//         <Grid
//           container
//           spacing={0}
//           direction="column"
//           alignItems="center"
//           justifyContent="center"
//           style={{ minHeight: "100vh" }}
//         >
//           <Grid item xs={3}>
//             <div className="loadingio-spinner-eclipse-zdin78qjk2">
//               <div className="ldio-5hg5t3mtcqb">
//                 <div></div>
//               </div>
//             </div>
//           </Grid>
//         </Grid>
//       )}
//     </>
//   );
// };

// export default Preloader;

import './preloader.scss';

import React from 'react';
import { useSelector } from 'react-redux';

const Preloader = () => {
  const state = useSelector((state) => state.preloader);
  return (
    <>
      {state?.loading && (
        <div className="preloader-container">
          <div className="loadingio-spinner-eclipse-zdin78qjk2">
            <div className="ldio-5hg5t3mtcqb">
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Preloader;